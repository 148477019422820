<!--能源-KPI--KPI警报管理---->
<template>
  <div id="eneKPIAlarmSetupIndex" v-loading="loading" :element-loading-text="$t('alarm.a0')">
    <!-- KPI警报管理  -->
    <my-header-title>{{ $t("energy.enedata592") }}</my-header-title> 
    <div class="header">
      <div class="header-left">
        
        <div class="header-condition">
            <span> {{$t('energy.enedata344')}}：</span>
            <a-select v-model="pointInfo" style="width: 200px;">
              <a-select-option :title="item.name"
                  v-for="(item, index) in pointList"
                  :key="index"
                  :value="item.key"
                >
                  {{ item.name }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <span> {{$t('alarm.a57')}}：</span>
            <a-select v-model="alarmLevel" style="width: 140px;">
              <a-select-option
                  v-for="(item, index) in alarmLevels"
                  :key="index"
                  :value="item.no"
                >
                  {{ item.text }}
                </a-select-option>
            </a-select>
        </div>
         <div class="header-condition">
            <span> {{$t('energy.enedata591')}}：</span>
            <a-select v-model="rule" style="width: 140px;">
              <a-select-option
                  v-for="(item, index) in yearRuleData"
                  :key="index"
                  :value="item.no"
                >
                  {{ item.name }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <span> {{$t('energy.setting.reportGr.dataKind')	}}：</span>
            <a-select v-model="kind" style="width: 80px;">
              <a-select-option
                  v-for="(item, index) in rptKinds"
                  :key="index"
                  :value="item.no"
                >
                  {{ item.text }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <span> {{(rptKinds && rptKinds.length>1)?rptKinds[1].text:'年'}}：</span>
            <a-select v-model="year" style="width: 100px;" @change="setMonthAndDayArray">
              <a-select-option
                  v-for="(item, index) in yearData"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <span> {{(rptKinds && rptKinds.length>2)?rptKinds[2].text:'月'}}：</span>
            <a-select v-model="month" style="width: 100px;" @change="setMonthAndDayArray">
              <a-select-option
                  v-for="(item, index) in monthData"
                  :key="index"
                  :value="item.no"
                >
                  {{ item.text }}
                </a-select-option>
            </a-select>
        </div>
        <div class="header-condition">
            <span> {{(rptKinds && rptKinds.length>3)?rptKinds[3].text:'日'}}：</span>
            <a-select v-model="day" style="width: 160px;" :disabled="month==''">
              <a-select-option
                  v-for="(item, index) in dayData"
                  :key="index"
                  :value="item.no"
                >
                  {{ item.text }}
                </a-select-option>
            </a-select>
        </div>
       
      </div>
      <div>
        <a-button type="primary" style="width: 120px"  @click="search()">{{$t('energy.enedata006')}} </a-button>
      </div>
    </div>
    <div class="main" ref="main">
         <!--  -->
         <a-table 
            :row-selection="rowSelection"
            :columns="columns" 
            :data-source="data"
            :row-key="record => record.id"  
            :pagination="pagination"  
            :scroll="tscroll"
            bordered size="middle" >
                    <template slot="chaozuo" slot-scope="text, record ">
                        <div class="editable-row-operations">
                            <a-button type="primary" @click="() => editRecord(record)" :disabled="getDisabled(record)" style="margin-right:5px;">{{$t('energy.enedata189')}}</a-button>
                          
                        </div>
                    </template>
        </a-table>
    </div>
    <div class="footer">
        <div>
            <a-button type="primary" style="width: 120px" @click="batchEditRecord" :disabled="selectedRowKeys.length==0">
              {{$t('energy.ok')}}
            </a-button>
          
        </div>
        
    </div>
     <a-modal  v-model="visible" :title="$t('energy.enedata592')" :ok-text="$t('energy.enedata023')" :width="360"
      :okButtonProps="{props:{loading:eloading}}" :cancelButtonProps="{props:{disabled:eloading}}" @ok="closeModal">
          <div class="fix-modal" style="width:300px; height:180px; overflow: hidden;" v-loading="eloading" :element-loading-text="$t('alarm.a0')">
          
          <a-descriptions title="" layout="vertical" size="small" bordered >
            <a-descriptions-item :label="$t('energy.enedata588')" :span="3">
             <a-switch v-model="executeEnable" :checked-children="$t('energy.enedata593')" :un-checked-children="$t('energy.enedata594')" />
    
            </a-descriptions-item>
            <a-descriptions-item :label="$t('alarm.a57')"  :span="3">
               
                <a-select v-model="alarmPriority" style="width: 100%;">
                  <a-select-option
                      v-for="(item, index) in alarmLevels"
                      :key="index"
                      :value="item.no"
                    >
                      {{ item.text }}
                    </a-select-option>
                </a-select>
            </a-descriptions-item>
          </a-descriptions>
          </div>
        </a-modal>

  </div>
</template>



<script>
import { getKPIAlarmList ,updateKPIAlarm   ,batchUpdateKPIAlarm} from "../../../api/energy";
import headerTitle from "../../../components/Title/headerTitle";
import moment from "moment";
export default {
  name: "eneKPIAlarmSetupIndex",
  data() {
    
    return {
      loading: false,
      data:[],
      pointList:[],
      rptKinds:[{no: '', text: this.$t("energy.enedata472")}],
      yearData:[{value: '', name: this.$t("energy.enedata472")}],
      monthData:[{no: '', text: this.$t("energy.enedata472")}],
      dayData:[{no: '', text: this.$t("energy.enedata472")}],
      alarmLevels:[],
      yearRuleData:[],
      pointInfo:'',
      kind:'',
      alarmLevel:'',
      year:'',
      month:'',
      day:'',
      rule:'',

      columns:[
         {title: 'NO'		 
          ,dataIndex: "NO",
            customRender: (text, record, index) =>
              `${
                (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                index +
                1
              }`,
            width: 60,},
         
          {title: this.$t('energy.setting.reportGr.pointName')		 , dataIndex: 'pointName'		  		      },
          {title: this.$t('energy.enedata591')		                 , dataIndex: 'yearRuleIdName'		  		,width:100      },
          {title: this.$t('energy.setting.reportGr.dataKind')		   , dataIndex: 'kindText'		  		,width:100      },
          {title: this.$t('energy.enedata005')		                 , dataIndex: 'fiscalYear'		  		,width:100      },
          {title: this.$t('energy.enedata585')		               , dataIndex: 'dateTime'		  		,width:120      },
          {title: this.$t('energy.enedata590')		               , dataIndex: 'aimValue'		  		,width:120      },
          {title: this.$t('alarm.a57')                      		 , dataIndex: 'alarmPriorityText'	,width:100      },
          {title: this.$t('energy.enedata588')		               , dataIndex: 'executeEnable'		   ,width:100  ,  
           customRender: (text, record, index) =>
              `${
                (record.executeEnable?this.$t('energy.enedata593'):this.$t('energy.enedata594')) //'停止''启用'
              }`,
          },
          {title: this.$t('energy.enedata586')		               , dataIndex: 'executeEnableDate'	,width:150      },

          // {title: this.$t('energy.enedata473')//'设备所属'		 
          // ,dataIndex: "parentId",
          //   customRender: (text, record, index) =>
          //     `${
          //       this.getGrName(record.parentId)
          //     }`,
          // },
        
          {
              title: this.$t('energy.enedata151'),
              dataIndex: "chaozuo",
              scopedSlots: { customRender: "chaozuo" },
              width: 200,
              fixed: "right",
          },
      ],
     
      pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
            let msg = this.$t("energy.enedata195");
            msg = msg.replace('${total}',total);
            return msg;
              // `共 ${total} 条数据`
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: false, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = pageSize;
            this.loadKPIAlarmList(1,current,pageSize);
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
            this.loadKPIAlarmList(1,current,size);
          },
      },
      
      selectedRowKeys:[],
      tscroll:{x:700,y:240},
      visible:false,
      eloading:false,
      executeEnable:false,
      alarmPriority:undefined,
      aimId:undefined,
    };
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onSelect: this.onSelectChange2,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:'checkbox',
        getCheckboxProps:record => ({
                    props: {
                        disabled: this.getDisabled(record),
                        name: record.pointName,
                    },
                }),
      };
    },
  },
  activated() {
    this.loadKPIAlarmList(0,0,0);
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  methods:{
    getDisabled(record){
        let now = new moment();
        let year = now.year();
        let month = now.month()+1;
        let day = now.date();
        console.log('GetDisabled>',year,month,day);
        if(record.kind==1){
          if(record.year<year){
            return true;
          }
        }else if(record.kind==2){
          if(record.year<year || (record.year==year && record.month<month)){
            return true;
          } 
        }else if(record.kind==3){
          if(record.year<year || (record.year==year && record.month<month)
            || (record.year==year && record.month==month && record.day<now.date() )){
            return true;
          } 
        }


        return false;
    },
    closeModal(e) {
        if(!this.alarmPriority || this.alarmPriority==''){
            this.$message.error(this.$t("energy.enedata597"));
            return ;
        }
        if(this.aimId){ 
            this.update();
        }else{
            this.batchUpdate();
        }
    },
    
       // 表格高度计算
    tableSize() {
        setTimeout(() => {
            let height = this.$refs.main.clientHeight;
            let width = this.$refs.main.clientWidth;
            let tableHeight = height - 100;
            this.tscroll.y = tableHeight;
            this.tscroll.x = width-50;
            console.log(width);
            console.log(tableHeight);
        }, 0);
        
    },
    editRecord(record){
      this.visible = true;
      this.aimId = record.id;
      this.alarmPriority = record.alarmPriority+'';
      this.executeEnable = record.executeEnable;
    },
    update(){
        let  params =  {
              action:1,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0,
              id:this.aimId,
              executeEnable:this.executeEnable,
              alarmPriority:this.alarmPriority,
          };
        this.eloading = true;
        updateKPIAlarm(params)
        .then((res) => {
            console.log("UpdateKPIAlarm Result",res);
            if(res.errorCode == '03'){
             
                this.$message.success(res.msg);
                this.aimId = undefined;
                this.visible = false;
                this.loadKPIAlarmList(1,this.pagination.defaultCurrent,this.pagination.pageSize);
            } else {
                this.$message.error(res.msg);
            }
            this.eloading = false;
            
        })
        .catch((err) => {
            this.eloading = false;
            console.log(err);
        });
    },
    batchEditRecord(){
        let len = this.selectedRowKeys.length;
        if(len<1){
            this.$message.error(this.$t("energy.enedata596"));
            return ;
        }
        this.visible = true;
        this.alarmPriority = '1';
        this.executeEnable = false;
        this.aimId = undefined;
    },
    
    batchUpdate(){
      let idList = this.selectedRowKeys;
      let  params =  {
            action:1,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            ids:idList,
            executeEnable:this.executeEnable,
            alarmPriority:this.alarmPriority,
        };
      
      this.eloading = true;
      batchUpdateKPIAlarm(params)
      .then((res) => {
          console.log("batchUpdateKPIAlarm Result",res);
          if(res.errorCode == '03'){
              this.$message.success(res.msg);
              this.aimId = undefined;
              this.visible = false;
              // this.search();
              this.selectedRowKeys=[];
              this.loadKPIAlarmList(1,this.pagination.defaultCurrent,this.pagination.pageSize);
          } else {
              this.$message.error(res.msg);
          }
          this.eloading = false;
      })
      .catch((err) => {
          this.eloading = false;
          console.log(err);
      });
       
      
        
    },
  
    loadKPIAlarmList(action,pageNum,pageSize){
        let params = null;
        if(action===1){
          let pointId = 0;
          let pointType = 0;
          if(this.pointInfo!=''){
            let points = this.pointInfo.split("@@");
            pointId   = points && points.length>0? points[0]:0;
            pointType = points && points.length>1? points[1]:0;
          }
          params =  {
              action:action,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0,
              pointId:pointId,
              pointType:pointType,
              pageNum:pageNum,
              pageSize:pageSize,
              kind:(this.kind==''?0:this.kind),
              year:(this.year==''?0:this.year),
              month:(this.month==''?0:this.month),
              day:(this.day==''?0:this.day),
              ruleId:(this.rule==''?0:this.rule),
              alarmPriority:this.alarmLevel,
          };
        }else{
          params =  {
              action:0,
              clientId: this.$store.getters.clientId,
              siteId: this.$route.query.id,
              sitegrId: this.$store.getters.sitegrId,
              tenantId: 0             
          };
        }
        console.log("InitPage Params",params);
        this.loading = true;
        getKPIAlarmList(params)
        .then((res) => {
            console.log("LoadData Result",res);
            if(res.errorCode == '00'){
              if(action==0){
                  this.pointList = [{key: "", name: this.$t("energy.enedata472")},...res.data.pointList];
                  this.alarmLevels = [...res.data.alarmLevels];
                  this.alarmLevels[0].text = this.$t("energy.enedata472");
                  this.rptKinds = [{no: "", text: this.$t("energy.enedata472")},...res.data.rptKinds];
                  this.year = res.data.fiscalYear;
                  this.yearData =  res.data.yearData;
                  this.yearRuleData = [{no: "", name: this.$t("energy.enedata472")},...res.data.yearRuleData];
                  this.monthData = [{no: "", text: this.$t("energy.enedata472")},...res.data.monthData];
                  this.setMonthAndDayArray();
              }else if(action==1){
                  this.data = res.data.list;
                  this.pagination.total = res.data.total;
                  this.tableSize();
              }
                
            } 
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
    },
    setMonthAndDayArray(){
      if(this.year && this.year!='' && this.month && this.month!=''){
          let year  = parseInt(this.year);
          let month = parseInt(this.month)-1;
          let day   = 1;

          let mom = new moment();
          mom.year(year);
          mom.month(month);
          mom.date(day);

          let num = mom.daysInMonth();

          let arry = [{no: '', text: this.$t("energy.enedata472")}];
          for(let n=1;n<=num;n++){
            arry.push({no:''+n,text:n+''});
          }

          this.dayData=arry;
          this.day = '';
      }
      

    },
    search(){
        this.loadKPIAlarmList(1,1,this.pagination.pageSize);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      console.log("selectedRowKeys changeddd: ", selectedRowKeys);
      console.log("selectedRowKeys changeddc: ", selectedRows);
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
     onSelectChange2(record, selected, selectedRows, nativeEvent) {
      console.log("onSelectChange2>>: ",selected, selectedRows);
      this.selectedRows    = selectedRows;
      this.selectedRowKeys = [];
      this.selectedRows.forEach(element => {
          this.selectedRowKeys.push(element.id);
      });
    },
  },
  components: {
    'my-header-title':headerTitle,
  },
};
</script>

<style scoped>

#eneKPIAlarmSetupIndex {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  position:relative;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid rgba(12, 12, 12, 0.1);
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 22px;
  margin-right: 20px;
  background-color: #7682ce;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
}
.header-left {
  display: flex;
}
.header-condition {
  display: flex;
  align-items: center;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 25px;
}
.main{
    width: 100%;
    height: calc(100% - 120px);
    box-shadow: 0px 3px 8px #e7e6e6;
}
.footer {
  position:absolute;
  bottom: 5px;
  display: flex;
  justify-content:space-between;
  padding: 10px 10px;
}

input {
  width: 14%;
}
button {
  width: 80px;
}
.footer button {
  margin-left: 15px;
}
</style>



